import React, { useState, useEffect } from 'react';
import { fetchCharts, voteForChart } from '../services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { faSpotify, faYoutube } from '@fortawesome/free-brands-svg-icons';

const ChartList = () => {
  const [charts, setCharts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const chartsPerPage = 10;

  useEffect(() => {
    fetchCharts()
      .then(response => {
        const sortedCharts = response.data.sort((a, b) => b.votes - a.votes);
        setCharts(sortedCharts);
      })
      .catch(error => console.error('Error fetching charts:', error));
  }, []);

  const handleVote = (songId) => {
    voteForChart(songId)
      .then(response => {
        console.log('Vote recorded:', response.data);
        fetchCharts()
          .then(response => {
            const sortedCharts = response.data.sort((a, b) => b.votes - a.votes);
            setCharts(sortedCharts);
          })
          .catch(error => console.error('Error fetching charts:', error));
      })
      .catch(error => console.error('Error voting:', error));
  };

  const indexOfLastChart = currentPage * chartsPerPage;
  const indexOfFirstChart = indexOfLastChart - chartsPerPage;
  const currentCharts = charts.slice(indexOfFirstChart, indexOfLastChart);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(charts.length / chartsPerPage); i++) {
    pageNumbers.push(i);
  }

  const now = new Date(); // Aktuelles Client-Datum zur Anzeige

  return (
    <div className="shadow-md rounded-lg p-6">
      <h2 className="text-white text-xl font-semibold mb-4">Hörercharts</h2>
      <ul className="space-y-4">
        {currentCharts.map((chart, index) => (
          <li key={chart.id} className="flex items-center p-4 border-b border-gray-300">
            <div className="flex items-center space-x-4">
              <span className="font-semibold text-xl text-white">{indexOfFirstChart + index + 1}.</span>
              {chart.cover_url && (
                <img
                  src={chart.cover_url}
                  alt={chart.name}
                  className="w-16 h-16 object-cover"
                />
              )}
            </div>
            <div className="flex-1 ml-4">
              <div className="flex items-center space-x-2 mb-2">
                {chart.spotify_url && (
                  <a
                    href={chart.spotify_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center w-8 h-8 bg-green-500 text-white rounded-full hover:bg-green-600 transition"
                  >
                    <FontAwesomeIcon icon={faSpotify} />
                  </a>
                )}
                {chart.youtube_url && (
                  <a
                    href={chart.youtube_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center w-8 h-8 bg-red-500 text-white rounded-full hover:bg-red-600 transition"
                  >
                    <FontAwesomeIcon icon={faYoutube} />
                  </a>
                )}
                <div>
                  <span className="font-semibold text-white">{chart.artist}</span>
                  <span className="text-white block">{chart.name}</span>
                </div>
              </div>
              <span className="text-white block">Hinzugefügt am: {new Date(chart.created_at).toLocaleDateString()}</span>
            </div>
            <div className="flex items-center space-x-4 ml-4">
              <span className="text-white">{chart.votes} votes</span>
              {/* Nur zur Anzeige: Zeige "Voting beendet", wenn das Enddatum überschritten ist */}
              {new Date(chart.voting_end_date) >= now ? (
                <button
                  onClick={() => handleVote(chart.id)}
                  className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 transition flex items-center"
                >
                  <FontAwesomeIcon icon={faPlay} className="mr-2" />
                  Vote
                </button>
              ) : (
                <span className="text-red-500">Voting beendet</span>
              )}
            </div>
          </li>
        ))}
      </ul>
      <div className="flex justify-center mt-4">
        <ul className="flex space-x-2">
          {pageNumbers.map(number => (
            <li key={number}>
              <button
                onClick={() => paginate(number)}
                className={`px-4 py-2 border rounded ${currentPage === number ? 'bg-blue-500 text-white' : 'bg-white bg-opacity-80 text-blue-500 border-blue-500'}`}
              >
                {number}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ChartList;
